import mime from 'mime-types'
import moment from 'moment/moment'
import {
	CALL_RECORDS_STORAGE,
	CHECK_LOAD_COLUMN_STORAGE,
	MAP_LOGGED_IN_INFO,
	SEARCH_INPUT_STORAGE,
	VIEW_TYPE_NOT_SHOW,
} from '../../constants'
import { endsWith, gt, gte, lt, lte, isEmpty, isEqual, startsWith, toNumber, cloneDeep } from 'lodash'
import { computeDateValue, parseAs } from './doformsDateUtil'
import {
	DATE_VALUE,
	LIST_DATE_Value,
	LIST_ONE_DAY,
} from 'components/pages/dis-v2/menu-items/_components/ConditionalColorPickerDialog/utils'

const MAX_SESSIONS_STORAGE_SIZE = 5000000

export const DATE_DATAGRID_FILTER_OPERATORS = {
	IS: 'is',
	IS_NOT: 'not',
	IS_AFTER: 'after',
	IS_ON_OR_AFTER: 'onOrAfter',
	IS_BEFORE: 'before',
	IS_ON_OR_BEFORE: 'onOrBefore',
	IS_EMPTY: 'isEmpty',
	IS_NOT_EMPTY: 'isNotEmpty',
}

export const NUMBER_DATAGRID_FILTER_OPERATORS = {
	EQUAL: '=',
	NOT_EQUAL: '!=',
	GREATER_THAN: '>',
	GREATER_THAN_OR_EQUAL_TO: '>=',
	LESS_THAN: '<',
	LESS_THAN_OR_EQUAL_TO: '<=',
	IS_EMPTY: 'isEmpty',
	IS_NOT_EMPTY: 'isNotEmpty',
	IS_ANY_OF: 'isAnyOf'
}

export const STRING_DATAGRID_FILTER_OPERATORS = {
	CONTAINS: 'contains',
	EQUALS: 'equals',
	STARTS_WITH: 'startsWith',
	ENDS_WITH: 'endsWith',
	IS_EMPTY: 'isEmpty',
	IS_NOT_EMPTY: 'isNotEmpty',
	IS_ANY_OF: 'isAnyOf'
}

export const LOGIC_OPERATOR = {
	AND: 'and',
	OR: 'or',
}

export const DEFAULT_LOGIC_OPERATOR = LOGIC_OPERATOR.AND

export const getLogicOperatorSelectList = (t) => {
	return [
		{
			value: LOGIC_OPERATOR.AND,
			label: t('common:misc.filterPanelOperatorAnd'),
		},
		{
			value: LOGIC_OPERATOR.OR,
			label: t('common:misc.filterPanelOperatorOr'),
		},
	]
}

export const getProjectName = (projectKey, projects) => {
	if (!projectKey || !projects) return
	if (!projects.length) return

	const found = projects.find((project) => project.key === projectKey)
	if (found) {
		return found.name
	}
	return ''
}

export const getFormName = (formKey, forms) => {
	if (!formKey || !forms) return
	if (!forms.length) return

	const foundForm = forms.find((form) => form.key === formKey)
	if (foundForm) {
		return foundForm.name
	}
	return ''
}

export const getDeviceName = (deviceKey, devices) => {
	if (!deviceKey || !devices) return
	if (!devices.length) return

	const found = devices.find((device) => device.key === deviceKey)
	if (found) {
		return found.name
	}
	return ''
}

export const checkNeedCallQueryAPI = (columnsKeys) => {
	const fullColumnsKeys = window.sessionStorage.getItem('full_columns_keys')
	const fullColumnsKeysData = fullColumnsKeys ? JSON.parse(fullColumnsKeys) : []

	if (fullColumnsKeysData.length === 0) return false
	return columnsKeys.some((key) => !fullColumnsKeysData.includes(key))
}

export function subtractSeconds(numOfSeconds, date = new Date()) {
	const dateCopy = new Date(date.getTime())

	dateCopy.setSeconds(dateCopy.getSeconds() - numOfSeconds)

	return dateCopy
}

export function isNotEqualWithPosition(objectA, objectB) {
	if (objectA.length !== objectB.length) return false
	return JSON.stringify(objectA) !== JSON.stringify(objectB)
}

function createSessionStorage(key) {
	const store = JSON.parse(sessionStorage.getItem(key)) ?? {}

	const save = () => {
		sessionStorage.setItem(key, JSON.stringify(store))
	}

	const storage = {
		get(key) {
			return store[key]
		},
		set(key, value) {
			store[key] = value
			save()
		},
		remove(key) {
			delete store[key]
			save()
		},
	}

	return storage
}
export const callRecordsStorage = createSessionStorage(CALL_RECORDS_STORAGE)
export const searchInputStorage = createSessionStorage(SEARCH_INPUT_STORAGE)

function createLocalStorage(key) {
	const store = JSON.parse(localStorage.getItem(key)) ?? {}

	const save = () => {
		localStorage.setItem(key, JSON.stringify(store))
	}

	const storage = {
		get(key) {
			return store[key]
		},
		set(key, value) {
			store[key] = value
			save()
		},
		remove(key) {
			delete store[key]
			save()
		},
	}

	return storage
}

export const checkLoadColumnStorage = createLocalStorage(CHECK_LOAD_COLUMN_STORAGE)

export const convertToKebabCase = (string) => {
	return string
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase()
}

export const getDisplayedViews = (views) => {
	if (!Array.isArray(views) || views?.length === 0) return []
	return views?.filter((view) => !VIEW_TYPE_NOT_SHOW.includes(view.type)) ?? []
}

export function isJson(str) {
	if (!str) return false
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export function logErrorMessage(error) {
	console.error('💥 ERROR::', error)
}

export function getUserTimeZone() {
	// Lấy múi giờ hiện tại của người dùng
	return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getDateInUserTimeZone(date) {
	const userTimeZone = getUserTimeZone()
	const dateOptions = { timeZone: userTimeZone }
	return new Date(date.toLocaleString('en-US', dateOptions))
}

export function getDefaultDateRange() {
	// Lấy ngày hiện tại
	const currentDate = new Date()

	// Lấy ngày mai
	const tomorrow = new Date(currentDate)
	tomorrow.setDate(currentDate.getDate() + 1)

	// Chuyển đổi ngày và giờ sang múi giờ của người dùng
	const fromDate = getDateInUserTimeZone(currentDate)
	const toDate = getDateInUserTimeZone(tomorrow)

	// Đặt giờ cho ngày bắt đầu và kết thúc
	fromDate.setHours(0, 0, 0, 0)
	toDate.setHours(0, 0, 0, 0)

	return { fromDate, toDate }
}

export function convertKmHToMph(kmH) {
	if (typeof kmH !== 'number') return 0
	const mph = kmH / 1.60934
	return Math.round(mph)
}

export function getMapLoggedInInfoFromStorage() {
	try {
		if (localStorage.getItem(MAP_LOGGED_IN_INFO)) {
			return JSON.parse(localStorage.getItem(MAP_LOGGED_IN_INFO))
		}
		return null
	} catch {
		return null
	}
}

export function checkAdminRole(userCurrent) {
	return userCurrent?.rights?.manage?.includes('update')
}

export function shouldShowDefaultFormSelector(permissions) {
	return permissions?.dispatch?.includes('create') || permissions?.submission?.includes('create')
}

export function hasUpdatingPermission(permissions) {
	return permissions?.submission?.includes('update')
}

export function hasCreatePermission(permissions) {
	return permissions?.submission?.includes('create')
}

export function isNumber(value) {
	if (!value) return false

	return !isNaN(value)
}

export function getAggregationModel(columns) {
	const aggregationModel = {}
	columns.forEach((column) => {
		if (column.aggregate) {
			aggregationModel[column?.name ?? column?.field] = column.aggregate
		}
	})
	return aggregationModel
}

//convert sort
//API: [{ column: 1, order: "DESC" }, { column: 2, order: "ASC" }]
//MUI Grid : [{field:"columnName",sort:"desc"},...]
export function getSortModel(allColumns, sortArr) {
	if (isEmpty(allColumns) || isEmpty(sortArr)) return []
	const sortArray = []
	sortArr.forEach((item) => {
		const column = allColumns[item.column]
		if (column) {
			sortArray.push({
				field: column?.name ?? column?.field,
				sort: item.order.toLowerCase(),
			})
		}
	})
	return sortArray
}

export function getRowGroupingModel(columns) {
	const rowGroupingModel = []
	columns.forEach((column) => {
		if (column.group) {
			rowGroupingModel.push(column.group)
		}
	})
	return [...new Set(rowGroupingModel)]
}

export function areDatesEqual(dateString1, dateString2, format = 'MM/DD/YYYY HH:mm:ss') {
	const date1 = moment(dateString1, format)
	const date2 = moment(dateString2, format)

	return date1.format('mm:ss') === date2.format('mm:ss')
}

export function getFileMimeType(fileType) {
	switch (fileType) {
		// Exception cases
		case 'mov':
			return 'video/mp4'
		// Main case
		default:
			return mime.lookup(fileType) || ''
	}
}

export function isIsoDate(value) {
	return !isNaN(Date.parse(value))
}

export function getColorWithoutId(color) {
	return color.split('-')[1]
}

export function compareDatesWithoutTime(date1, date2, userOffset, operationType) {
	// list standard date
	if (LIST_DATE_Value.includes(date2)) {
		if (LIST_ONE_DAY.includes(date2)) {
			const valueDate = computeDateValue(date2)
			const computedValuesISO = valueDate.map((item) => {
				if (!item) return ''
				const userMoment = parseAs(item, userOffset)
				return userMoment?.toISOString()
			})
			date2 = computedValuesISO[0]
		} else {
			const valueDate = computeDateValue(date2)
			const computedValuesISO = valueDate.map((item) => {
				if (!item) return ''
				return parseAs(item, userOffset)
			})
			if (!date1) return -2
			const moment1 = moment.utc(date1)
			const moment2 = computedValuesISO[0]
			const moment3 = computedValuesISO[1]

			if (moment1.isBetween(moment2, moment3)) {
				return 0
			} else if (moment1.isAfter(moment2)) {
				return 1
			} else {
				return -1
			}
		}
	}
	// if compare 2 null
	if (!date1 && !date2) return 0

	const d1 = moment.utc(date1)
	const d2 = moment.utc(date2)
	if (d1.format('MM/DD/YYYY') === d2.format('MM/DD/YYYY')) return 0

	const diff = d1.diff(d2)
	if (diff < 0) {
		return -1 // First date is smaller than the second date
	} else {
		return 1 // First date is greater than the second date
	}
}

export function compareDateTime(date1, date2, userOffset, operationType) {
	let isOnlyDay = false
	if (LIST_DATE_Value.includes(date2)) {
		if (LIST_ONE_DAY.includes(date2)) {
			isOnlyDay = true
			const valueDate = computeDateValue(date2)
			const computedValuesISO = valueDate.map((item) => {
				if (!item) return ''
				const userMoment = parseAs(item, userOffset)
				return userMoment?.toISOString()
			})
			date2 = computedValuesISO[0]
		} else {
			const valueDate = computeDateValue(date2)
			const computedValuesISO = valueDate.map((item) => {
				if (!item) return ''
				return parseAs(item, userOffset)
			})
			if (!date1) return -2
			const moment1 = moment.utc(date1)
			const moment2 = computedValuesISO[0]
			const moment3 = computedValuesISO[1]

			if (moment1.isBetween(moment2, moment3)) {
				return 0
			} else if (moment1.isAfter(moment2)) {
				return 1
			} else {
				return -1
			}
		}
	}
	// if compare 2 null value
	if (!date1 && !date2) return 0

	const moment1 = moment.utc(date1)
	const moment2 = moment.utc(date2)

	if (!moment1.isValid() || !moment2.isValid()) return -2
	if (isOnlyDay === true) {
		if (moment1.format('MM/DD/YYYY') === moment2.format('MM/DD/YYYY')) return 0
	} else {
		if (moment1.isSame(moment2)) return 0
	}
	//if (moment1.isSame(moment2)) return 0
	if (moment1.isAfter(moment2)) return 1
	return -1
}

export function compareTimeWithoutDate(time1, time2, format) {
	if (!time1 && !time2) return 0

	const fullTimeFormat = 'h:mm:ss A'
	const momentFormat = format ?? fullTimeFormat
	const moment1 = moment(time1, momentFormat)
	const moment2 = moment(time2, momentFormat)

	if (!moment1.isValid() || !moment2.isValid()) return -2

	if (moment1.isSame(moment2)) return 0
	if (moment1.isAfter(moment2)) return 1
	return -1
}

export function isNullOrEmptyString(valueToCheck) {
	return valueToCheck === undefined || valueToCheck === ''
}

export function sleep(ms) {
	return new Promise((r) => setTimeout(r, ms))
}

export function saveSizedSessionStorage(key, data) {
	const valueRecords = JSON.stringify(data)
	if (valueRecords.length >= MAX_SESSIONS_STORAGE_SIZE) return

	try {
		window.sessionStorage.setItem(key, valueRecords)
	} catch (error) {
		console.log(error)
	}
}

export function getSizedSessionStorage(key) {
	const dataAtIndex = window.sessionStorage.getItem(key)
	const parsedData = JSON.parse(dataAtIndex)
	return parsedData
}

export function getLinkPrefix(url) {
	return '/dis/default' + url
}

function getDatagridOperators(operator, preset, type) {
	const isDateTime = type === 'DATETIME'
	if (preset) {
		switch (preset) {
			case 'EMPTY':
				return [DATE_DATAGRID_FILTER_OPERATORS.IS_EMPTY]
			case 'NOTEMPTY':
				return [DATE_DATAGRID_FILTER_OPERATORS.IS_NOT_EMPTY]
			case 'ALL':
				return null
			case 'TODAY':
			case 'YESTERDAY':
			case 'TOMORROW':
				return isDateTime
					? [
						DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER,
						DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE,
					]
					: [DATE_DATAGRID_FILTER_OPERATORS.IS]
			default:
				return [
					DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER,
					DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE,
				]
		}
	} else {
		switch (operator) {
			case 'EQ':
			case 'CT':
				return isDateTime
					? [
						DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER,
						DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE,
					]
					: [DATE_DATAGRID_FILTER_OPERATORS.IS]
			case 'NE':
				return isDateTime
					? [
						DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE,
						DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER,
					]
					: [DATE_DATAGRID_FILTER_OPERATORS.IS_NOT]
			case 'BT':
				return [
					DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER,
					DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE,
				]
			default:
				return null
		}
	}
}

export function generateRandomID() {
	return Math.random().toString(36).substring(2)
}

function isEmptyValue(value) {
	return value === null || value === undefined || value === ""
}

export function validateDateTimeFilter(valueToCheck, condition, granularity = 'date') {
	if (isEmpty(condition)) return true

	const valueMoment = moment.utc(valueToCheck)
	if (!valueMoment.isValid()) return false

	const { operator, value } = condition
	const expectedValue = moment.utc(value)

	switch (operator) {
		case DATE_DATAGRID_FILTER_OPERATORS.IS:
			return valueMoment.isSame(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_NOT:
			return !valueMoment.isSame(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_AFTER:
			return valueMoment.isAfter(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_AFTER:
			return valueMoment.isSameOrAfter(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_BEFORE:
			return valueMoment.isBefore(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_ON_OR_BEFORE:
			return valueMoment.isSameOrBefore(expectedValue, granularity)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_EMPTY:
			return isEmptyValue(valueToCheck)
		case DATE_DATAGRID_FILTER_OPERATORS.IS_NOT_EMPTY:
			return !isEmptyValue(valueToCheck)
		default:
			return false
	}
}

export function validateStringFilter(valueToCheck, condition) {
	if (isEmpty(condition)) return true

	const { operator, value } = condition

	switch (operator) {
		case STRING_DATAGRID_FILTER_OPERATORS.CONTAINS:
			return valueToCheck?.includes(value?.toString())
		case STRING_DATAGRID_FILTER_OPERATORS.EQUALS:
			return isEqual(valueToCheck, value?.toString())
		case STRING_DATAGRID_FILTER_OPERATORS.STARTS_WITH:
			return startsWith(valueToCheck, value?.toString())
		case STRING_DATAGRID_FILTER_OPERATORS.ENDS_WITH:
			return endsWith(valueToCheck, value?.toString())
		case STRING_DATAGRID_FILTER_OPERATORS.IS_EMPTY:
			return isEmptyValue(valueToCheck)
		case STRING_DATAGRID_FILTER_OPERATORS.IS_NOT_EMPTY:
			return !isEmptyValue(valueToCheck)
		case STRING_DATAGRID_FILTER_OPERATORS.IS_ANY_OF: {
			if (!Array.isArray(value)) return false

			if (isEmpty(value)) return isEmpty(valueToCheck)

			return value.some(conditionValue => isEqual(conditionValue, valueToCheck))
		}
		default:
			return false
	}
}

export function validateNumberFilter(valueToCheck, condition) {
	if (isEmpty(condition)) return true

	const numberToCheck = toNumber(valueToCheck)

	const { operator, value } = condition

	switch (operator) {
		case NUMBER_DATAGRID_FILTER_OPERATORS.EQUAL:
			return isEqual(numberToCheck, toNumber(value))
		case NUMBER_DATAGRID_FILTER_OPERATORS.NOT_EQUAL:
			return !isEqual(numberToCheck, toNumber(value))
		case NUMBER_DATAGRID_FILTER_OPERATORS.GREATER_THAN:
			return gt(numberToCheck, toNumber(value))
		case NUMBER_DATAGRID_FILTER_OPERATORS.GREATER_THAN_OR_EQUAL_TO:
			return gte(numberToCheck, toNumber(value))
		case NUMBER_DATAGRID_FILTER_OPERATORS.LESS_THAN:
			return lt(numberToCheck, toNumber(value))
		case NUMBER_DATAGRID_FILTER_OPERATORS.LESS_THAN_OR_EQUAL_TO:
			return lte(numberToCheck, toNumber(value))
		case NUMBER_DATAGRID_FILTER_OPERATORS.IS_EMPTY:
			return isEmptyValue(valueToCheck)
		case NUMBER_DATAGRID_FILTER_OPERATORS.IS_NOT_EMPTY:
			return !isEmptyValue(valueToCheck)
		case NUMBER_DATAGRID_FILTER_OPERATORS.IS_ANY_OF:
			if (!Array.isArray(value)) return false

			if (isEmpty(value)) return isEmpty(valueToCheck)

			return value.some(conditionValue => isEqual(conditionValue?.toNumber(), numberToCheck))
		default:
			return false
	}
}

function checkDateCondition(valueToCheck, operator, preset, values = [], granularity) {
	const valueMoment = moment.utc(valueToCheck)
	if (!valueMoment.isValid()) return false

	values = preset ? computeDateValue(preset) : values
	const fromDateMoment = moment.utc(values[0])
	const toDateMoment = moment.utc(values[1])

	if (preset) {
		switch (preset) {
			case 'EMPTY':
				return isEmpty(valueToCheck?.toString())
			case 'NOTEMPTY':
				return !isEmpty(valueToCheck?.toString())
			case 'ALL':
				return true
			case 'TODAY':
			case 'YESTERDAY':
			case 'TOMORROW':
				return valueMoment.isSame(fromDateMoment, granularity)
			default:
				return valueMoment.isBetween(fromDateMoment, toDateMoment, granularity, '[]')
		}
	} else {
		switch (operator) {
			case 'EQ':
			case 'CT':
				return valueMoment.isSame(fromDateMoment, granularity)
			case 'NE':
				return !valueMoment.isSame(fromDateMoment, granularity)
			case 'BT':
				return valueMoment.isBetween(fromDateMoment, toDateMoment, granularity, '[]')
			default:
				return false
		}
	}
}

function checkTextCondition(valueToCheck, operator, preset, values = []) {
	if (preset) {
		if (preset === 'ALL') return true
		return isEqual(valueToCheck, preset)
	}

	const fromText = values[0]
	const toText = values[1]
	switch (operator) {
		case 'EQ':
		case 'CT':
			return isEqual(valueToCheck, fromText)
		case 'NE':
			return !isEqual(valueToCheck, fromText)
		case 'BT':
			return isEqual(valueToCheck, fromText)
		default:
			return false
	}
}

export function checkByMasterDateTimeCondition(valueToCheck, condition, isDateTimeCondition) {
	if (isEmpty(condition)) return true
	const { type: operator, preset, values } = condition
	if (isDateTimeCondition) {
		return checkDateCondition(valueToCheck, operator, preset, values, 'day')
	}

	return checkTextCondition(valueToCheck, operator, preset, values)
}

export function buildDatagridDateFilterModel(condition, customOptions = {}) {
	const { type: operator, preset, values, columnType } = condition
	const dateOperators = getDatagridOperators(operator, preset, columnType)

	// PRESET === "ALL"
	if (isEmpty(dateOperators)) return []
	const isRange = dateOperators?.length === 2
	if (!isRange) {
		return [
			{
				...customOptions,
				operator: dateOperators[0],
				value: values[0],
				id: generateRandomID(),
			},
		]
	}

	let valueToCheck = []
	if (values.length === 1) {
		const selectedDate = values[0]

		const fromDateTime = moment(selectedDate)?.clone().startOf('day')
		const endDateTime = moment(selectedDate)?.clone().endOf('day')

		valueToCheck.push(fromDateTime)
		valueToCheck.push(endDateTime)
	} else {
		valueToCheck = values
	}
	return dateOperators.map((operator, index) => ({
		...customOptions,
		operator,
		value: valueToCheck[index],
		id: generateRandomID(),
	}))
}

const dataGroupKeyPrefix = '_index_'

export function convertToDisplayKey(index, groupKey) {
	return `${index}${dataGroupKeyPrefix}${groupKey}`
}

export function convertFromDisplayKey(displayKey) {
	return displayKey.split(dataGroupKeyPrefix)?.[1] || ''
}

export function toViewWizardUserFiltersData(originalData) {
	if (!originalData || !Array.isArray(originalData) || isEmpty(originalData)) return {}

	const displayData = {}
	const uniqueDataGroupFilter = []
	originalData.forEach((dataGroupFilter) => {
		const dataGroupKey = dataGroupFilter?.dataGroupKey
		if (!dataGroupKey) return

		let index = uniqueDataGroupFilter.length
		if (uniqueDataGroupFilter.includes(dataGroupKey)) {
			index = uniqueDataGroupFilter.indexOf(dataGroupKey)
		} else {
			uniqueDataGroupFilter.push(dataGroupKey)
		}
		const customKey = convertToDisplayKey(index, dataGroupKey)
		const currentValue = displayData[customKey]
		if (isEmpty(currentValue)) {
			displayData[customKey] = [dataGroupFilter]
		} else {
			displayData[customKey] = [...currentValue, dataGroupFilter]
		}
	})
	return displayData
}

export function toOriginalUserFiltersData(viewWizardUserFilters) {
	if (isEmpty(viewWizardUserFilters)) return []

	const dbUserFiltersSetting = []
	Object.keys(viewWizardUserFilters).forEach((key) => {
		const currentValue = viewWizardUserFilters[key]
		const dataGroupKey = key.split(dataGroupKeyPrefix)?.[1]
		if (isEmpty(currentValue)) return

		if (currentValue.length > 1) {
			const newFirstItem = cloneDeep(currentValue[0])

			newFirstItem.logicOperator = currentValue[1].logicOperator
			currentValue.splice(0, 1, newFirstItem)
		}

		dbUserFiltersSetting.push(
			...currentValue.map((groupFilter) => ({
				...groupFilter,
				dataGroupKey: dataGroupKey,
			}))
		)
	})
	return dbUserFiltersSetting
}

export function getViewLogicOperator(clientFilters, environment) {
	if (isEmpty(clientFilters)) return LOGIC_OPERATOR.AND

	const userFilters = clientFilters.filter(
		(filters) =>
			filters.dataGroupKey && filters.dataGroupKey === environment.userCurrent.dataGroupKey
	)
	const additionalFilters = clientFilters.filter((filters) => !filters.dataGroupKey)

	const filtersToGetLogicOperator = isEmpty(userFilters) ? additionalFilters : userFilters
	for (let filter of filtersToGetLogicOperator) {
		if (filter.logicOperator) {
			return filter.logicOperator
		}
	}

	return LOGIC_OPERATOR.AND
}

export function sortDataGroupFilterKeyFn(a, b) {
	const aIndex = a.split(dataGroupKeyPrefix)?.[0]
	const bIndex = b.split(dataGroupKeyPrefix)?.[0]
	return aIndex - bIndex
}
