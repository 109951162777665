import MenuItem from '@mui/material/MenuItem'
import {
	GridCsvExportMenuItem,
	GridPrintExportMenuItem,
	GridToolbarExportContainer,
	gridFilteredSortedRowIdsSelector,
	useGridApiContext,
} from '@mui/x-data-grid-premium'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser'
import saveAs from 'file-saver'
import _ from 'lodash'
import moment from 'moment'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { formatDate } from 'utils/functions/doformsDateUtil'
import * as XLSX from 'xlsx/xlsx.mjs'
import { getTimeZoneOffset } from '../dataHelpers'

const METHOD_LIST = {
	SUM: 'sum',
	AVERAGE: 'avg',
	MIN: 'min',
	MAX: 'max',
	SIZE: 'size',
}

const getUserDateTimeFormat = (environment, columnType) => {
	const hasUserDateTimeFormat = !!environment.userCurrent.time
	const userDateFormat = hasUserDateTimeFormat ? (environment.userCurrent.time.dateFormat).toUpperCase() : null
	const userTimeFormat = hasUserDateTimeFormat ? environment.userCurrent.time.timeFormat : null
	
	switch (columnType) {
		case 'date':
			return userDateFormat || 'MM/DD/YYYY'
        case 'datetime':
			if (hasUserDateTimeFormat) return userDateFormat + ' ' + userTimeFormat
			return 'MM/DD/YYYY HH:mm:ss'
		default:
			return null
	}
}
	

const CustomGridExcelExportMenuItem = ({
	viewData,
	hideMenu,
	gridRows,
	gridColumns,
	records,
	aggregationModel,
	isGrouping,
}) => {
	const [t] = useTranslation('common')
	const apiRef = useGridApiContext()
	const environment = useSelector((state) => state.environment)

	const titleOfchart = () => {
		let methodSelected = viewData.chart.method
		let xAxisSelectedTitle = ''
		let yAxisSelectedTitle = ''
		let yAxis2SelectedTitle = ''
		let yAxis3SelectedTitle = ''
		if (viewData.chart.xAxis && viewData.chart.xAxis.length > 0) {
			xAxisSelectedTitle = viewData.chart.xAxis[0].title
		}
		if (viewData.chart.yAxis && viewData.chart.yAxis.length > 0) {
			if (viewData.chart.yAxis.length > 0) {
				yAxisSelectedTitle = viewData.chart.yAxis[0].title
			}
			if (viewData.chart.yAxis.length > 1) {
				yAxis2SelectedTitle = viewData.chart.yAxis[1].title
			}
			if (viewData.chart.yAxis.length > 2) {
				yAxis3SelectedTitle = viewData.chart.yAxis[2].title
			}
		}
		let colValName =
			methodSelected === 'count'
				? t('common:chart.count')
				: methodSelected === 'sum'
					? t('common:chart.total')
					: t('common:chart.average')
		switch (methodSelected) {
			case 'count':
				return colValName + ` ${t('common:chart.by')} ` + xAxisSelectedTitle
			default:
				let ofLabel = ''
				let s = ''
				if (yAxisSelectedTitle) {
					ofLabel += s
					ofLabel += yAxisSelectedTitle
					s = ', '
				}
				if (yAxis2SelectedTitle) {
					ofLabel += s
					ofLabel += yAxis2SelectedTitle
					s = ', '
				}
				if (yAxis3SelectedTitle) {
					ofLabel += s
					ofLabel += yAxis3SelectedTitle
				}
				return (
					colValName +
					` ${t('common:chart.of')} ` +
					ofLabel +
					` ${t('common:chart.by')} ` +
					xAxisSelectedTitle
				)
		}
	}

	const getFilteredData = (apiRef) => {
		const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef)
		const visibleColumnsField = gridColumns.map((item) => {
			return item.title
		})
		let data = []
		data.push(visibleColumnsField)
		filteredSortedRowIds.map((id, index) => {
			const row = []
			gridColumns.forEach((item) => {
				row.push(apiRef.current.getCellParams(id, item.field).value)
			})
			data.push(row)
			return row
		})

		return data
	}

	const exportChart = (chartType, chartTitle, chart_ds, chart_ws, featureSelected) => {
		var cs2 = XLSX.utils.aoa_to_sheet([[]])
		cs2['!type'] = 'chart'
		cs2['!title'] = chartTitle
		cs2['!legend'] = { pos: 'r' }
		cs2['!plot'] = []
		cs2['!pos'] = { x: 500, y: 150, w: 700, h: 400 }

		var chart = {
			t: chartType,
			ser: [],
		}

		if (chartType == 'bar') {
			if (featureSelected.length) {
				chart.grouping = 'stacked'
			}
		}

		let length = chart_ds.length + 1
		for (var i = 1; i < chart_ds[0].length; i++) {
			let column
			switch (i) {
				case 1:
					column = 'B'
					break
				case 2:
					column = 'C'
					break
				case 3:
					column = 'D'
					break
				default:
					break
			}
			var rgb = Math.floor(Math.random() * 16777215).toString(16)
			chart.ser.push({
				name: chart_ds[0][i],
				cols: ['xVal', 'yVal'],
				ranges: ["'Chart'!A2:A" + length, "'Chart'!" + column + '2:' + column + length],
			})
			if (chartType == 'line' || chartType == 'bar') {
				chart.ser.raw = true
				chart.ser.linecolor = { rgb: rgb }
			}
		}

		cs2['!plot'].push(chart)

		/* add chart to worksheet */
		if (!chart_ws['!charts']) chart_ws['!charts'] = []
		chart_ws['!charts'].push(cs2)
	}

	const exportChartExcelJS = (chartTitle, chart_ds, chart_ws, wb_img) => {
		if (!chart_ds) return
		let length = chart_ds.length + 1
		for (var i = 1; i < chart_ds[0].length; i++) {
			let column
			switch (i) {
				case 1:
					column = 'B'
					break
				case 2:
					column = 'C'
					break
				case 3:
					column = 'D'
					break
				default:
					break
			}
		}
		var headerRow = chart_ws.addRow()
		var columns = chart_ds[0]
		for (let i = 0; i < columns.length; i++) {
			let cell = headerRow.getCell(i + 1)
			cell.value = columns[i]
		}

		for (let i = 1; i < chart_ds.length; i++) {
			var dataRow = chart_ws.addRow()
			for (let j = 0; j < chart_ds[i].length; j++) {
				let cell = dataRow.getCell(j + 1)
				cell.value = chart_ds[i][j]
			}
		}

		if (wb_img != null && wb_img >= 0) {
			var chartRowId = chart_ds.length + 1
			/*chart_ws.mergeCells("A" + chartRowId + ":D" + chartRowId);
		const chartTitleCell = chart_ws.getCell("A" + chartRowId);
		chartTitleCell.value = chartTitle;*/

			//chart_ws.mergeCells("A" + chartRowId + 1 + ":D" + chartRowId + 1);
			chart_ws.addImage(wb_img, {
				tl: { col: 0, row: chartRowId + 1 },
				ext: { width: 1024, height: 300 },
			})
		}
	}

	return (
		<MenuItem
			onClick={() => {
				let doformsDataGridChart = ReactDOM.findDOMNode(
					document.getElementById('DoformsDataGridChart')
				)
				let base64Image = null
				if (doformsDataGridChart) {
					let canvas = doformsDataGridChart.getElementsByTagName('canvas')
					if (canvas && canvas.length > 0) {
						base64Image = canvas[0].toDataURL('image/png')
					}
				}

				var useExcelJS = true
				if (useExcelJS) {
					var wb = new ExcelJS.Workbook()
					let data = getFilteredData(apiRef)
					var ws = wb.addWorksheet('Activity')
					var headerRow = ws.addRow()
					var columns = data[0]
					for (let i = 0; i < columns.length; i++) {
						let cell = headerRow.getCell(i + 1)
						cell.value = columns[i]
					}

					let offset = getTimeZoneOffset(environment, records)
					let totalMap = {}

					for (let i = 1; i < data.length; i++) {
						var dataRow = ws.addRow()

						for (let j = 0; j < data[i].length; j++) {
							let cell = dataRow.getCell(j + 1)
							const rowValue = data[i][j]
							const columnHeader = columns[j]

							const currentColumn = gridColumns?.find((item) => item.headerName === columnHeader)
							const currentColumnType = currentColumn?.type?.toLowerCase()
							const formatDateConfig = currentColumn?.format?.date 
								? currentColumn?.format?.date
								: getUserDateTimeFormat(environment, currentColumnType)
							const isNumber = !isNaN(rowValue)
							const currencyFormat = currentColumn?.format?.currency

							const newRowValue = rowValue ?? 0
							// loop through aggregation model to get total, aggregationModel with format {'Post_Trip_Total': 'sum'}
							_.forOwn(aggregationModel, function (method, key) {
								if (key === currentColumn.field) {
									if (method === METHOD_LIST.SUM) {
										totalMap[key] = (totalMap[key] ?? 0) + newRowValue
									} else if (method === METHOD_LIST.AVERAGE) {
										totalMap[key] = (totalMap[key] ?? 0) + newRowValue
									} else if (method === METHOD_LIST.MIN) {
										totalMap[key] = Math.min(totalMap[key] ?? newRowValue, newRowValue)
									} else if (method === METHOD_LIST.MAX) {
										totalMap[key] = Math.max(totalMap[key] ?? newRowValue, newRowValue)
									} else if (method === METHOD_LIST.SIZE) {
										totalMap[key] = (totalMap[key] ?? 0) + 1
									}
								}
							})

							if (!currentColumn || !rowValue) {
								cell.value = rowValue
								continue
							}

							if (isNumber && currencyFormat) {
								const formattedValue = new Intl.NumberFormat(currencyFormat, {
									...currentColumn?.format,
									minimumFractionDigits: Number.isInteger(rowValue) ? 0 : undefined,
								}).format(rowValue)

								cell.value = !rowValue ? '' : formattedValue
							} else if (formatDateConfig) {
								if (currentColumn.type === 'date') {
									const formattedDate = rowValue.format(formatDateConfig)

									cell.value = formattedDate
								} else if (currentColumn && currentColumn.type === 'dateTime') {
									const formattedDate = formatDate(rowValue, offset, formatDateConfig)

									cell.value = formattedDate
								} else {
									const formattedDate = moment(rowValue, formatDateConfig).format(formatDateConfig)

									cell.value = formattedDate
								}
							} else {
								cell.value = rowValue
							}
						}
					}

					var dataEndRow = ws.addRow()

					// Loop through totalMap to add total row to the end of excel
					_.forOwn(totalMap, function (value, key) {
						const currentColumnIndex = gridColumns?.findIndex((item) => item.field === key)

						if (currentColumnIndex > -1) {
							let cell = dataEndRow.getCell(currentColumnIndex + 1)
							let newValue

							if (isGrouping) {
								newValue = value / 2
							} else {
								newValue = value
							}

							// Change average value to average
							if (aggregationModel[key] === METHOD_LIST.AVERAGE) {
								cell.value = newValue / records.length
							} else {
								cell.value = newValue
							}
						}
					})

					if (gridRows && gridRows.length > 0 && viewData?.chart?.display) {
						let wb_img = null
						if (base64Image) {
							wb_img = wb.addImage({
								base64: base64Image,
								extension: 'png',
							})
						}
						let chart_ws = wb.addWorksheet('Chart')

						let chartTitle = titleOfchart()
						exportChartExcelJS(chartTitle, environment.chartDS, chart_ws, wb_img)
					}

					wb.xlsx.writeBuffer().then(function (buffer) {
						saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `doForms.xlsx`)
					})
				} else {
					var wb = XLSX.utils.book_new()
					let data = getFilteredData(apiRef)
					const fileType =
						'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
					const fileExtension = '.xlsx'

					let ws = XLSX.utils.aoa_to_sheet(data)

					XLSX.utils.book_append_sheet(wb, ws, 'Activity')
					if (gridRows && gridRows.length > 0 && viewData?.chart?.display) {
						let chart_ws = XLSX.utils.aoa_to_sheet(environment.chartDS)
						XLSX.utils.book_append_sheet(wb, chart_ws, 'Chart')

						let chartSelected = viewData.chart.type
						let chartTitle = titleOfchart()
						let featureSelected = viewData.chart.features
						switch (chartSelected) {
							case 'lineChart':
								exportChart('line', chartTitle, environment.chartDS, chart_ws, featureSelected)
								break
							case 'barChart':
								exportChart('bar', chartTitle, environment.chartDS, chart_ws, featureSelected)
								break
							case 'scatterPlot':
								exportChart('scatter', chartTitle, environment.chartDS, chart_ws, featureSelected)
								break
							case 'pieChart':
								exportChart('pie', chartTitle, environment.chartDS, chart_ws, featureSelected)
								break
							default:
								break
						}
					}

					XLSX.writeFile(wb, 'doForms.xlsx')
				}

				// Hide the export menu after the export
				hideMenu?.()
			}}
		>
			{t('common:misc.exportExcel')}
		</MenuItem>
	)
}

const ExportButton = (props) => {
	return (
		<GridToolbarExportContainer style={props?.sx}>
			<GridCsvExportMenuItem />
			<CustomGridExcelExportMenuItem
				viewData={props?.viewData}
				hideMenu={props?.hideMenu}
				gridRows={props?.gridRows}
				gridColumns={props?.gridColumns}
				records={props?.records}
				aggregationModel={props?.aggregationModel}
				isGrouping={props?.isGrouping}
			/>
			<GridPrintExportMenuItem options={{
				hideToolbar: true,
				hideFooter: true,
			}} />
		</GridToolbarExportContainer>
	)
}

export default ExportButton
