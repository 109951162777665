import { TextField } from '@mui/material'
import { getGridDateOperators } from '@mui/x-data-grid-premium'
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns as DateAdapter } from '@mui/x-date-pickers/AdapterDateFns'
import enUS from 'date-fns/locale/en-US'
import _, { isEmpty, intersection } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as R from 'ramda'

import { ACTIVITY_RECEIVE_TIME_KEY } from '../../constants'
import { compareDates, formatDate } from '../../utils/functions/doformsDateUtil'
import { DEFAULT_LOGIC_OPERATOR, validateDateTimeFilter, validateNumberFilter, validateStringFilter } from 'utils/functions/helpers'

export const defaultStatusName = ['@UserStatus', '@FeedDeviceId']

export const computeColType = (type) => {
	switch (type) {
		case 'DATETIME':
			return 'dateTime'
		case 'DATE':
			return 'date'
		case 'BOOLEAN':
			return 'boolean'
		case 'FLOAT':
		case 'INTEGER':
			return 'number'
		default:
			return 'string'
	}
}

export const currencyFormatter = (columnFormat) => {
	return new Intl.NumberFormat(navigator.language, {
		style: columnFormat.style,
		currency: columnFormat.currency,
		maximumFractionDigits: columnFormat.maximumFractionDigits,
		minimumFractionDigits: columnFormat.minimumFractionDigits,
	})
}

export const stringToHTML = (str) => {
	let parser = new DOMParser()
	let doc = parser.parseFromString(str, 'text/html')
	return doc.body.innerText
}

export const DateInputValue = (props) => {
	const [t] = useTranslation('common')
	const { item, applyValue, focusElementRef } = props
	const [value, setValue] = useState(null)
	const [locale, setLocale] = useState(enUS)

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])

	const handleFilterChange = (newValue) => {
		let momentObj = moment(newValue)
		if (momentObj.isValid()) {
			applyValue({ ...item, value: momentObj.format('YYYY-MM-DDTHH:mm') })
		}
	}

	return (
		<>
			<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
				<DatePicker
					label={t('common:filters.value')}
					value={item.value}
					onChange={handleFilterChange}
					renderInput={(params) => <TextField {...params} variant="standard" focused />}
				/>
			</LocalizationProvider>
		</>
	)
}

export const DateTimeInputValue = (props) => {
	const [t] = useTranslation('common')
	const { item, applyValue, focusElementRef } = props
	const [value, setValue] = useState(null)
	const [locale, setLocale] = useState(enUS)

	useEffect(() => {
		const importLocaleFile = async () => {
			const localeToSet = await import(
				`date-fns/locale/${t('common:languages.dateFnsLocale')}/index.js`
			)
			setLocale(localeToSet.default)
		}

		if (locale.code !== t('common:languages.dateFnsLocale')) {
			importLocaleFile()
		}
	}, [t('common:languages.dateFnsLocale')])

	const handleFilterChange = (newValue) => {
		let momentObj = moment(newValue)
		if (momentObj.isValid()) {
			applyValue({ ...item, value: new Date(newValue) })
		}
	}

	return (
		<>
			<LocalizationProvider dateAdapter={DateAdapter} locale={locale}>
				<DateTimePicker
					label={t('common:filters.value')}
					value={item.value}
					onChange={handleFilterChange}
					renderInput={(params) => <TextField {...params} variant="standard" focused />}
				/>
			</LocalizationProvider>
		</>
	)
}

export const parsedDataGridColumns = (environment, record, columns) => {
	const fullDateFormat = 'MM/DD/YYYY'
	const fullDateTimeFormat = 'MM/DD/YYYY h:mm A'
	const useOneTimezone = environment?.userCurrent?.time?.useOneTimezone

	let columnsResult = (columns || []).map((column) => {
		let format = column?.format?.date ?? fullDateFormat
		if (column.type === 'DATE') {
			format = column?.format?.date ?? fullDateFormat
		} else if (column.type === 'DATETIME') {
			format = column?.format?.date ?? fullDateTimeFormat
		}
		let offset = getTimeZoneOffset(environment, record)
		const isNeedToFormat = !isEmpty(column?.format)

		if (column.type === 'DATE') {
			const filterOperas = getGridDateOperators().map((operator) => ({
				...operator,
				getApplyFilterFn: (filterItem, column) => {
					if (!filterItem.field || !filterItem.operator) {
						return null
					}

					return (value, row, column, apiRef) => {
						return validateDateTimeFilter(value, filterItem, 'day')
					}
				},
				InputComponent: operator.InputComponent ? DateInputValue : undefined,
				InputComponentProps: { type: 'date' },
			}))
			return {
				aggregate: column?.aggregate,
				group: column?.group,
				field: column.name,
				headerName: column.title,
				title: column.title,
				type: computeColType(column.type),
				orgType: column.type,
				headerAlign: 'left',
				align: 'left',
				hide: column.hidden ? column.hidden : false,
				format: column.format,
				width: column.width || 170,
				filterOperators: filterOperas,
				sortComparator: compareDates,
				valueGetter: (value) => {
					if (!value) {
						return ''
					}

					// not set timezone for Date type
					return moment.parseZone(value).utcOffset(0, true)
				},
				valueFormatter: (value) => {
					if (!value) {
						return ''
					}
					return !value ? '' : formatDate(value, null, format)
				},
			}
		} else if (column.type === 'DATETIME') {
			const filterOperas = getGridDateOperators().map((operator) => {
				return {
					...operator,
					getApplyFilterFn: (filterItem, column) => {
						if (!filterItem.field || !filterItem.operator) {
							return null
						}

						return (value, row, column, apiRef) => {
							return validateDateTimeFilter(value, filterItem)
						}
					},
					InputComponent: operator.InputComponent ? DateTimeInputValue : undefined,
					InputComponentProps: { type: 'dateTime' },
				}
			})
			return {
				aggregate: column?.aggregate,
				group: column?.group,
				field: column.name,
				headerName: column.title,
				title: column.title,
				type: computeColType(column.type),
				orgType: column.type,
				headerAlign: 'left',
				align: 'left',
				hide: column.hidden ? column.hidden : false,
				format: column.format,
				width: column.width || 170,
				filterOperators: filterOperas,
				sortComparator: compareDates,
				// OLD VERSION
				// valueFormatter: ({ value }) => {
				// 	// If useOneTimezone is false, get value from default row value, because it format already
				// 	if (useOneTimezone === false) {
				// 		return value
				// 	} else {
				// 		return _.isEmpty(value) ? value : formatDate(value, offset, format)
				// 	}
				// },
				// valueGetter: ({ value }) => {
				// 	// If useOneTimezone is false, get value from default row value, because it format already
				// 	if (useOneTimezone === false) {
				// 		return value
				// 	} else {
				// 		return _.isEmpty(value) ? value : formatDate(value, offset, format)
				// 	}
				// },

				// NEW VERSION TO FIX BUG FILTER WITH BEFORE OPERATOR
				valueGetter: (value, row) => {
					if (!value) {
						return ''
					}

					const momentValue = moment(value, format)
					return momentValue.toDate()
					// return moment(value)
					// return value
				},
				valueFormatter: (value) => {
					if (!value) {
						return ''
					}

					//If useOneTimezone is false, get value from default row value, because it format already
					// if (useOneTimezone === false) {
					// 	return formatDate(value, null, format)
					// } else {
					// 	return !value ? '' : formatDate(value, offset, format)
					// }
					return formatDate(value, null, format)
				},
			}
		} else if (column.type === 'TIME') {
			return {
				aggregate: column?.aggregate,
				group: column?.group,
				field: column.name,
				headerName: column.title,
				title: column.title,
				type: computeColType(column.type),
				orgType: column.type,
				headerAlign: 'left',
				align: 'left',
				hide: column.hidden ? column.hidden : false,
				format: column.format,
				width: column.width || 170,
				filterOperators: getGridDateOperators().map((operator) => ({
					...operator,
					InputComponent: operator.InputComponent ? DateTimeInputValue : undefined,
					InputComponentProps: { type: 'dateTime' },
				})),
				sortComparator: compareDates,
				valueGetter: (value) => {
					if (!value) {
						return ''
					}
					return moment(value, format)
				},
				valueFormatter: (value) => {
					if (!value) {
						return ''
					}

					// If useOneTimezone is false, get value from default row value, because it format already
					if (useOneTimezone === false) {
						return formatDate(value, null, format)
					} else {
						return !value ? '' : formatDate(value, offset, format)
					}
				},
			}
		} else if (isNeedToFormat) {
			const columnType = computeColType(column.type)
			return {
				aggregate: column?.aggregate,
				group: column?.group,
				field: column.name,
				headerName: column.title,
				title: column.title,
				type: columnType,
				orgType: column.type,
				headerAlign: 'left',
				align: 'left',
				hide: column.hidden ? column.hidden : false,
				format: column.format,
				width: column.width || 170,
				valueGetter: (rowValue) =>
					columnType === 'number' ? Number(rowValue) || 0 : rowValue || '',
				// valueFormatter: (rowValue) => {
				// 	const value = rowValue || ''
				// 	const isNumber = columnType === 'number'

				// 	// If the value is a number, format it as a currency
				// 	if (isNumber) {
				// 		let formattedValue

				// 		formattedValue = new Intl.NumberFormat(column?.format?.currency, {
				// 			...column.format,
				// 			minimumFractionDigits: Number.isInteger(value) ? 0 : undefined,
				// 		}).format(value)

				// 		return !value ? '' : formattedValue
				// 	}

				// 	return !value ? '' : `${value}`
				// },
				renderCell: (rowValue) => {
					const isNumber = columnType === 'number'
					const value = rowValue.value

					// If the value is a number, format it as a currency
					if (isNumber) {
						let formattedValue

						formattedValue = new Intl.NumberFormat(column?.format?.currency, {
							...column.format,
							minimumFractionDigits: Number.isInteger(value) ? 0 : undefined,
						}).format(value)

						return !value ? (rowValue.rowNode.type === 'leaf' ? 0 : '') : <div dangerouslySetInnerHTML={{ __html: formattedValue }} />
					}

					return !value ? '' : <div dangerouslySetInnerHTML={{ __html: value }} />
				},
			}
		} else {
			const columnType = computeColType(column.type)
			return {
				aggregate: column?.aggregate,
				group: column?.group,
				field: column.name,
				headerName: column.title,
				title: column.title,
				type: columnType,
				orgType: column.type,
				headerAlign: 'left',
				align: 'left',
				hide: column.hidden ? column.hidden : false,
				format: column.format,
				width: column.width || 170,
				valueGetter: (rowValue) =>
					columnType === 'number' ? Number(rowValue) || 0 : rowValue || '',
				renderCell: (rowValue) => {
					const isNumber = columnType === 'number'
					const value = rowValue.value || (rowValue.rowNode.type === 'leaf' && isNumber ? 0 : '')
					return !value ? '' : <div dangerouslySetInnerHTML={{ __html: value }} />
				},
			}
		}
	})

	return columnsResult
}

export const revertDataGridColumns = (columns) => {
	let columnsResult = columns.map((column) => {
		return {
			name: column?.field,
			title: column?.headerName,
			type: column?.orgType,
			width: column?.width,
			format: column?.format,
		}
	})

	return columnsResult
}

export const parsedDataGridRecords = (records = [], columns, environment, gridRowIDs) => {
	let rowsResult = records.map((record, index) => {
		return {
			id: index + 1,
			'@displayId': index + 1,
			recordKey: record.type === 'DISPATCH' ? record.dispatchKey : record.submissionKey,
			recordType: record.type,
			...mapColRecords(columns, record, environment),
		}
	})
	return rowsResult
}

export const getSelectedGridRecordWithoutFilter = ({ records, columns, columnCheckList }) => {
	let rowsResult = (records || []).map((record, index) => {
		return {
			id: index + 1,
			'@displayId': index + 1,
			recordKey: record.type === 'DISPATCH' ? record.dispatchKey : record.submissionKey,
			recordType: record.type,
			...mapColRecordsWithoutFormatDate({ columns, record, columnCheckList }),
		}
	})
	return rowsResult
}

export const getSelectedGridRecord = ({ records, columns, environment, columnCheckList }) => {
	let rowsResult = (records || []).map((record, index) => {
		return {
			id: index + 1,
			'@displayId': index + 1,
			recordKey: record.type === 'DISPATCH' ? record.dispatchKey : record.submissionKey,
			recordType: record.type,
			...mapColRecordsWithSelected({ columns, record, environment, columnCheckList }),
		}
	})
	return rowsResult
}

export const computeColumns = (view) => {
	let array = [{ field: 'id', headerName: 'ID', width: '75', hide: true }]
	view.columns.forEach((column) => {
		let result = {
			field: column.name,
			headerName: column.title,
			type: computeColType(column.type),
			headerAlign: 'left', //align left column header
			align: 'left', //align left cell row
			format: column.format,
			flex: 1,
		}
		if (!_.isEmpty(column.format)) {
			let formatStyle = column.format.style
			let formatUnderlying = column.format.underlying
			if (formatStyle) {
				if (column.format.style === 'currency') {
					result = {
						...result,
						valueFormatter: (params) => {
							const value = params?.value || 0
							return currencyFormatter(column.format).format(Number(value))
						},
					}
				} else if (column.format.style === 'percent') {
					result = {
						...result,
						valueFormatter: (params) => {
							return `${params?.value} %`
						},
					}
				}
			} else if (formatUnderlying) {
				result = {
					...result,
					valueFormatter: (params) => {
						if (params?.value) {
							return stringToHTML(params.value).replace(/\s/gi, '; ').replace(/_/g, ' ')
						}
						return params?.value || ''
					},
				}
			}
		} else if (['INTEGER', 'FLOAT'].includes(column.type)) {
			result = {
				...result,
				valueFormatter: (params) => {
					if (params?.value) {
						let val = params.value.toString()
						if (!val) return

						if (val.length > 3) {
							val = val.replace(/,/g, '')
						}
						val = parseInt(val)
						return val
					}
					return
				},
			}
		}
		array.push(result)
	})
	return array
}

export const computeRows = (view, columns, records, environment) => {
	if (!columns.length) return
	let rows = []
	if (records.length) {
		records.forEach((record, recordIndex) => {
			// let offset = getTimeZoneOffset(environment, record)
			let result = mapColRecords(view.columns, record)
			result.id = recordIndex + 1
			result.key = computeRecordKey(record)
			result.type = record.type
			rows.push(result)
		})
	}
	return rows
}

const computeRecordKey = (record) => {
	if (record.type === 'SUBMISSION') {
		return record.submissionKey
	} else if (record.type === 'DISPATCH') {
		return record.dispatchKey
	} else {
		return ''
	}
}

const mapColRecords = (columns, record, environment) => {
	const { values } = record
	// If user one time zone is false, it's mean Device time is selected. This means that the offset recorded with the record should be used to convert the time
	const useOneTimezone = environment?.userCurrent?.time?.useOneTimezone
	let recordOffset

	if (useOneTimezone === false) {
		recordOffset = record?.meta?.offset
	} else {
		recordOffset = getTimeZoneOffset(environment, record)
	}

	let map = new Map()
	// if(columns.length !== recordValues.length) return {};
	for (let i = 0; i < columns?.length; i++) {
		const columnData = columns[i]
		const valueData = values[i]

		if (columnData.hidden) continue

		switch (columnData.type) {
			case 'DATETIME': {
				const fullDateFormat = 'MM/DD/YYYY h:mm A'
				const format = columnData?.format?.date ?? fullDateFormat
				const dateValue = _.isEmpty(valueData)
					? valueData
					: formatDate(valueData, recordOffset, format)

				map.set(columnData.name, dateValue)
				break
			}
			case 'TIME': {
				const fullTimeFormat = 'h:mm:ss A'
				const format = columnData?.format?.date ?? fullTimeFormat
				const timeMoment = _.isEmpty(valueData) ? '' : moment(valueData, fullTimeFormat)
				map.set(columnData.name, formatDate(timeMoment, recordOffset, format))
				break
			}
			default: {
				map.set(columnData.name, valueData)
				break
			}
		}
	}
	return Object.fromEntries(map.entries())
}

const mapColRecordsWithoutFormatDate = ({ columns, record, columnCheckList }) => {
	const { values } = record

	let map = new Map()
	for (let i = 0; i < columns.length; i++) {
		const columnData = columns[i]
		const columnIndex = columnCheckList.findIndex((c) => c.name === columns[i].name)
		// Base on columnIndex, set value
		const valueData = values[columnIndex]

		if (columnData.hidden) continue

		map.set(columnData.name, valueData)
	}
	return Object.fromEntries(map.entries())
}

const mapColRecordsWithSelected = ({ columns, record, environment, columnCheckList }) => {
	const { values } = record
	// If user one time zone is false, it's mean Device time is selected. This means that the offset recorded with the record should be used to convert the time
	const useOneTimezone = environment?.userCurrent?.time?.useOneTimezone
	let recordOffset

	if (useOneTimezone === false) {
		recordOffset = record?.meta?.offset
	} else {
		recordOffset = getTimeZoneOffset(environment, record)
	}

	let map = new Map()

	for (let i = 0; i < columns.length; i++) {
		const columnData = columns[i]
		const columnIndex = columnCheckList.findIndex((c) => c.name === columns[i].name)
		// Base on columnIndex, set value
		const valueData = values[columnIndex]

		if (columnData.hidden) continue

		if (['TIME', 'DATETIME'].includes(columnData.type)) {
			const fullDateFormat = 'MM/DD/YYYY h:mm A'
			const format = columnData?.format?.date ?? fullDateFormat
			const dateValue = _.isEmpty(valueData)
				? valueData
				: formatDate(valueData, recordOffset, format)

			map.set(columnData.name, dateValue)
		} else {
			map.set(columnData.name, valueData)
		}
	}
	return Object.fromEntries(map.entries())
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getSelectedProject = (environment, formSelected, tab) => {
	let result = ''
	if (formSelected?.projectName?.toLowerCase() === 'lookups') {
		if (_.isEmpty(environment.lookups)) return
		result = environment.lookups
		return result
	}
	switch (tab) {
		case 'lookups':
			if (_.isEmpty(environment.lookups)) break
			result = environment.lookups
			break
		default:
			if (!environment.projects.length) return formSelected
			result =
				environment.projects.find((project) => project.key === formSelected.projectKey) ??
				formSelected
			break
	}
	return result
}

export const getSelectedFormInfo = (environment, projectInfo) => {
	return environment.forms.find((form) => form.key === projectInfo.key) || {}
}

export const getSelectedViewOwner = (environment, viewSelected, tab) => {
	return environment.owners.find((owner) => owner.key === viewSelected.ownerKey)
}

export const getLinkTarget = (links, linkObj) => {
	let target = links.find(
		(el) => el.target === linkObj?.target && el.operation === linkObj?.operation
	)
	if (!target) return null

	return target
}

export const showColumnTitleByName = (target, columns, t) => {
	if (!target || isEmpty(columns)) return ''
	if (target === '@StartTime') return t('common:filters.startDate')
	if (target === '@UserStatus') return t('common:filters.status')
	if (target === ACTIVITY_RECEIVE_TIME_KEY) return t('common:filters.dateReceived')
	let foundColumn = columns.find((col) => col.name === target)
	return foundColumn ? foundColumn.title : t('common:filters.noTitle')
}

export const computeDateOperatorValue = (condition) => {
	const preset = condition.preset ? condition.preset : null
	const type = condition.type ? condition.type : null

	return preset ? preset : type
}

export const computeNonDateOperatorValue = (condition) => {
	const preset = condition.preset ? condition.preset : null
	const type = condition.type ? condition.type : null

	if (defaultStatusName.includes(condition.target)) {
		if (preset !== null) {
			return preset
		}
	}
	return type
}

export const computeOwnerNameByType = (ownerType, t) => {
	if (ownerType === 'USER') {
		return t('formsData.personalViews')
	} else if (ownerType === 'CUSTOMER') {
		return t('formsData.sharedViews')
	}
}

export const getTimeZoneOffset = (environment, record) => {
	// environment.userCurrent.time.timezone.offset already apply DST
	const currentUserTimeZone = environment.userCurrent.time.timezone.offset || 0
	if (record?.meta == null || !record?.meta?.offset) {
		return currentUserTimeZone
	}
	let offset = !environment.userCurrent.time.useOneTimezone
		? record.meta
			? record.meta.offset || 0
			: record.offset
				? record.offset
				: 0
		: currentUserTimeZone
	return offset
}

export function getDisplayTimezone(environment) {
	const isDstUser = environment?.userCurrent?.time?.timezone?.isDst || false
	const currentTimezoneName = environment?.userCurrent?.time?.timezone?.name || ''
	if (isDstUser) {
		return currentTimezoneName?.concat(' DST')
	}

	return currentTimezoneName
}

export function getSavedFilterList(clientFilters) {
	if (Array.isArray(clientFilters)) {
		// If 'clientFilters' is an array, return 'clientFilters'
		return clientFilters
	} else if (clientFilters && typeof clientFilters === "object" && "items" in clientFilters) {
		// If 'clientFilters' is an object and has 'items' property, return 'clientFilters.items'
		return clientFilters.items
	}

	return []
}

export function getViewClientFilters(clientFilters, userDataGroupKey = '') {
	const filters = getSavedFilterList(clientFilters)
	if (isEmpty(filters)) return []

	const userSpecificFilters = []
	if (userDataGroupKey) {
		userSpecificFilters.push(
			...filters.filter((filter) => filter.dataGroupKey === userDataGroupKey)
		)
	}

	const viewClientFilters = clientFilters.filter((filter) => !filter.dataGroupKey)
	return [...userSpecificFilters, ...viewClientFilters]
}

function compareFnByType(valueToCheck, condition) {
	const typeToCompare = computeColType(condition.type)
	switch (typeToCompare) {
		case 'date':
			return validateDateTimeFilter(valueToCheck, condition, 'day')
		case 'dateTime':
			return validateDateTimeFilter(valueToCheck, condition)
		case 'number':
			return validateNumberFilter(valueToCheck, condition)
		case 'string':
			return validateStringFilter(valueToCheck, condition)
		default:
			return false
	}
}

function buildConditions(allConditions) {
	const andConditions = []
	const orConditions = []

	let currentAndIndex = 0
	let preCondition = null
	for (let index = 0; index < allConditions.length; index++) {
		const condition = allConditions[index]
		const logicOperator = condition.logicOperator?.toLowerCase() || DEFAULT_LOGIC_OPERATOR
		const field = condition.field
		const rCondition = R.pipe(R.prop(field), (field) => compareFnByType(field, condition))
		if (logicOperator === DEFAULT_LOGIC_OPERATOR) {
			const currentAndCondtions = andConditions[currentAndIndex] || []
			if (isEmpty(currentAndCondtions) && preCondition) {
				currentAndCondtions.push(preCondition, rCondition)

				// remove the precontion (last index) from OR list
				if (orConditions.length > 0) {
					orConditions.splice(orConditions.length - 1, 1)
				}
			} else {
				currentAndCondtions.push(rCondition)
			}

			preCondition = rCondition
			andConditions.splice(currentAndIndex, 1, currentAndCondtions)
		} else {
			orConditions.push(rCondition)
			preCondition = rCondition
			if (isEmpty(andConditions)) continue
			currentAndIndex++
		}
	}

	return {
		andConditions: andConditions || [],
		orConditions: orConditions || []
	}
}

export function getFilteredRows(filters, allRows) {
	if (isEmpty(allRows)) return []
	const allConditions = getSavedFilterList(filters)
	if (isEmpty(allConditions)) return allRows

	const additionalFilters = []
	const dataGroupFilters = []
	allConditions.forEach((item) => {
		if (item.dataGroupKey) {
			dataGroupFilters.push(item)
			return
		}

		additionalFilters.push(item)
	})

	let additionalFilteredRows = []
	let dataGroupFilteredRows = []

	const hasAdditionalFilters = !isEmpty(additionalFilters)
	const hasDataGroupFilters = !isEmpty(dataGroupFilters)
	if (hasAdditionalFilters) {
		const additionalConditions = buildConditions(additionalFilters)
		const additionalFilterConditions = R.anyPass([
			...additionalConditions.orConditions,
			...additionalConditions.andConditions.map(item => R.allPass(item))
		])

		additionalFilteredRows.push(...R.filter(additionalFilterConditions, allRows))
		if (!hasDataGroupFilters) return additionalFilteredRows
	}

	if (hasDataGroupFilters) {
		const dataGroupConditions = buildConditions(dataGroupFilters)
		const dataGroupFilterConditions = R.anyPass([
			...dataGroupConditions.orConditions,
			...dataGroupConditions.andConditions.map(item => R.allPass(item))
		])

		dataGroupFilteredRows.push(...R.filter(dataGroupFilterConditions, allRows))
		if (!hasAdditionalFilters) return dataGroupFilteredRows
	}

	return intersection(additionalFilteredRows, dataGroupFilteredRows)
}
