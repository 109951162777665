import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useEffect, useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { checkLoadColumnStorage, checkNeedCallQueryAPI } from '../../../utils/functions/helpers'
import { useFirstRender } from 'utils/hooks/useFirstRender'
import useGetPathName from 'utils/hooks/useGetPathName'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderDataGrid from '../../../custom-components/skeletons/SkeletonLoaderDataGrid'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import DoformsWizardDataGrid from '../../data/datagrid/DoformsWizardDataGrid'
import { getSelectedViewOwner, getViewClientFilters } from '../../data/dataHelpers'
import {
	cancelLoadNextRecordsAction,
	getProject,
	getViewTabViewRecords,
	loadForm,
	loadRecordsQuery,
} from '../../data/dataServices'
import { getLinkPrefix, areDatesEqual } from '../../../utils/functions/helpers'
import DoformsMap from '../../data/map/DoformsMap'
import { ACTIVITY_DEVICE_LIST } from '../../../constants'
import useGetCommonFunc from 'utils/hooks/useGetCommonFunc'
import { MOBILE_UNIT_VIEW_TYPE } from 'components/data/datagrid/CreatViewComponents/ViewDialogUtils'
import { off, onValue, ref } from 'firebase/database'
import database from '../../../firebase-config'
import moment from 'moment'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	dataGridToolbar: {
		minHeight: 20,
		paddingLeft: '0px',
		paddingBottom: '8px',
	},
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},

	loading: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	datagrid: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: '30px',
		padding: '8px',
	},
}))

const ViewsData = ({ environment, module }) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const isFirstRender = useFirstRender()
	const firstPathName = useGetPathName()

	const apiToken = environment.apiToken
	const {
		action,
		formColumns,
		dataGrid: { columns, query, queryView, records, title, titleFooter, viewSession },
		formDeleteSelection,
		formFilterConditions,
		viewRefresh,
		viewSelected,
		viewCreate,
		viewData,
		viewUpdate,
		viewDeleted,
		viewUnavailable,
		rowsPerPage,
		clientFilters,
		serverClientFilters
	} = module
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [info, setInfo] = useState(null)
	const [linkViewInfo, setLinkViewInfo] = useState(null)

	const [allRecordsLoading, setAllRecordsLoading] = useState(false)
	const [startLoadAllRecords, setStartLoadAllRecords] = useState(false)
	const [recordLoading, setRecordLoading] = useState(false)
	const [isCancelLoadingRecords, setIsCancelLoadingRecords] = useState(false)

	const { getAllRecords, loadUpdateColumns } = useGetCommonFunc({
		tab: 'views',
		firstPathName,
		viewData,
		query,
		queryView,
		viewSession,
		apiToken,
		records,
		formColumns,
		setAllRecordsLoading,
		setStartLoadAllRecords,
		setLoading,
		setError,
	})

	useEffect(() => {
		// Checked when change column then switch tab will call back api to get correct data
		; (async () => {
			if (
				!_.isEmpty(formColumns) &&
				viewData.key &&
				isFirstRender &&
				checkLoadColumnStorage.get(firstPathName)
			) {
				await loadUpdateColumns()
				checkLoadColumnStorage.set(firstPathName, false)
				setRecordLoading(true)
			}
		})()
	}, [])

	useEffect(() => {
		checkLoadColumnStorage.set(firstPathName, false)
	}, [])

	useEffect(() => {
		if (_.isEmpty(viewSelected)) return
		const selectedOwner = getSelectedViewOwner(environment, viewSelected)
		const viewTitle = (selectedOwner ? selectedOwner.name + ` / ` : '') + viewSelected.name
		dispatch({
			type: VIEWS_ACTIONS.DATA_GRID,
			payload: {
				title: viewTitle,
			},
		})
	}, [viewSelected])

	useLayoutEffect(() => {
		if (recordLoading) {
			loadRecords()
			setRecordLoading(false)
		}
	}, [recordLoading])

	useEffect(() => {
		if (_.isEmpty(viewSelected)) return
		let displayValue = ''
		switch (viewSelected.type) {
			case 'ACTIVITY_CUSTOMER':
				dispatch({
					type: VIEWS_ACTIONS.DATA_GRID,
					payload: {
						titleFooter: displayValue,
					},
				})
				return
		}

		if (viewSelected.projectKey) {
			getProject(viewSelected.projectKey, environment.apiToken)
				.then((response) => {
					if (_.isEmpty(displayValue)) {
						displayValue = response.data.name
					} else {
						dispatch({
							type: VIEWS_ACTIONS.DATA_GRID,
							payload: {
								titleFooter: response.data.name + ` / ` + displayValue,
							},
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => { })
		}
		if (viewSelected.formKey) {
			loadForm(viewSelected.formKey, environment.apiToken)
				.then((response) => {
					if (_.isEmpty(displayValue)) {
						displayValue = response.data.name
					} else {
						dispatch({
							type: VIEWS_ACTIONS.DATA_GRID,
							payload: {
								titleFooter: displayValue + ` / ` + response.data.name,
							},
						})
					}
				})
				.catch((err) => {
					console.log(err)
				})
				.finally(() => { })
		}
	}, [viewSelected])

	const getViewData = () => {
		let hasNoTitle = viewSelected.type === MOBILE_UNIT_VIEW_TYPE ? false : !title
		if (!viewSelected.key || !viewSelected.ownerKey || hasNoTitle) return
		setLoading(true)
		getViewTabViewRecords({
			viewKey: viewSelected.key,
			viewSession,
			token: apiToken,
			viewType: viewSelected.type,
		})
			.then((response) => {
				let responseData = response.data

				const clientFilter = responseData?.clientFilter
					? JSON.parse(responseData?.clientFilter)
					: undefined

				const currentFiltersWithUserFilter = getViewClientFilters(
					clientFilter,
					environment?.userCurrent?.dataGroupKey
				)
				if (currentFiltersWithUserFilter) {
					const clientFilterKey = viewSelected?.key ?? ''
					dispatch({
						type: VIEWS_ACTIONS.SERVER_CLIENT_FILTERS,
						payload: {
							...serverClientFilters,
							[clientFilterKey]: currentFiltersWithUserFilter,
						},
					})
				}
				dispatch({
					type: VIEWS_ACTIONS.VIEW_DATA,
					payload: responseData,
				})
				dispatch({
					type: VIEWS_ACTIONS.DATA_GRID,
					payload: {
						columns: responseData.columns,
						query: responseData.queries[0],
					},
				})
				setRecordLoading(true)
			})
			.catch((err) => {
				if (err.response?.data) {
					setError('Code ' + err.response.data.code + ': ' + err.response?.data?.message)
				} else {
					setError(err.message)
				}
			})
	}

	useEffect(() => {
		if (!action || action !== 'audit') return
		if (!_.isEmpty(queryView)) return
		dispatch({
			type: VIEWS_ACTIONS.VIEW_DATA,
			payload: {},
		})
		dispatch({
			type: VIEWS_ACTIONS.DATA_GRID,
			payload: {
				columns: [],
			},
		})
		dispatch({
			type: VIEWS_ACTIONS.VIEW_DELETED,
			payload: false,
		})
		dispatch({
			type: VIEWS_ACTIONS.VIEW_UNAVAILABLE,
			payload: false,
		})
		getViewData()
	}, [action, viewSelected, title])

	useEffect(() => {
		if (_.isEmpty(formFilterConditions)) return
		if (query.filter?.conditions && _.isEqual(formFilterConditions, query.filter.conditions)) return
		dispatch({
			type: VIEWS_ACTIONS.DATA_GRID,
			payload: {
				query: {
					...query,
					filter: {
						conditions: formFilterConditions,
					},
				},
			},
		})
		setRecordLoading(true)
	}, [formFilterConditions])

	useEffect(() => {
		; (async () => {
			if (!viewRefresh) return
			try {
				setIsCancelLoadingRecords(false)
				if (
					!_.isEmpty(formColumns) &&
					!isFirstRender &&
					checkLoadColumnStorage.get(firstPathName)
				) {
					await loadUpdateColumns()
					checkLoadColumnStorage.set(firstPathName, false)
				}
				loadRecords()
			} catch (err) {
				if (err.response?.data) {
					setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
				}

				setError(err)
			}
		})()
	}, [viewRefresh])

	useEffect(() => {
		if (_.isEmpty(viewData)) return
		const viewType = viewData.type

		if (viewType === MOBILE_UNIT_VIEW_TYPE) return
		const formKey = viewData.formKey
		const projectKey = viewData.projectKey

		let serverOffset = 0
		const offsetRef = ref(database, ".info/serverTimeOffset")

		const unsubscribeOffset = onValue(offsetRef, (snapshot) => {
			serverOffset = snapshot.val() || 0; // Offset in milliseconds
		})

		if (viewType === 'RECORD' && formKey && projectKey) {
			const formRef = ref(database, 'formSessions/' + projectKey + '/' + formKey)
			const unsubscribe = onValue(formRef, (snapshot) => {
				// Process data when there is a change
				const data = snapshot.val()
				const currentDateTime = moment().add(serverOffset, "milliseconds").format('MM/DD/YYYY HH:mm:ss')

				if (data && areDatesEqual(data?.Updated, currentDateTime, 'MM/DD/YYYY HH:mm:ss')) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_REFRESH,
						payload: true,
					})
				}
			})

			// Returns the off function to turn off event listening when the component unmounts
			return () => {
				off(formRef)
				unsubscribeOffset()
				unsubscribe()
			}
		} else {
			const customerKey = environment.account.key
			if (!customerKey) return


			const customerCountRef = ref(database, `userSessions/customerKey/${customerKey}`)
			const viewRef = ref(database, `userSessions/viewActivity/customerKey/${customerKey}`)
			const timeSheetRef = ref(database, `userSessions/timeSheet/customerKey/${customerKey}`)
			const geoTabRef = ref(database, `userSessions/geotab/customerKey/${customerKey}`)

			const customerUnsubscribe = onValue(customerCountRef, (snapshot) => {
				const data = snapshot.val()
				const currentDateTime = moment().add(serverOffset, "milliseconds").format('MM/DD/YYYY HH:mm:ss')

				if (data && areDatesEqual(data?.Updated, currentDateTime, 'MM/DD/YYYY HH:mm:ss')) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_REFRESH,
						payload: true,
					})
				}
			})

			const viewUnsubscribe = onValue(viewRef, (snapshot) => {
				const data = snapshot.val()
				const currentDateTime = moment().add(serverOffset, "milliseconds").format('MM/DD/YYYY HH:mm:ss')

				if (data && areDatesEqual(data?.Updated, currentDateTime, 'MM/DD/YYYY HH:mm:ss')) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_REFRESH,
						payload: true,
					})
				}
			})

			const timeSheetUnsubscribe = onValue(timeSheetRef, (snapshot) => {
				const data = snapshot.val()
				const currentDateTime = moment().add(serverOffset, "milliseconds").format('MM/DD/YYYY HH:mm:ss')

				if (data && areDatesEqual(data?.Updated, currentDateTime, 'MM/DD/YYYY HH:mm:ss')) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_REFRESH,
						payload: true,
					})
				}
			})

			const geoTabUnsubscribe = onValue(geoTabRef, (snapshot) => {
				const data = snapshot.val()
				const currentDateTime = moment().add(serverOffset, "milliseconds").format('MM/DD/YYYY HH:mm:ss')

				if (data && areDatesEqual(data?.Updated, currentDateTime, 'MM/DD/YYYY HH:mm:ss')) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_REFRESH,
						payload: true,
					})
				}
			})

			// Returns the off function to turn off event listening when the component unmounts
			return () => {
				off(customerCountRef)
				off(viewRef)
				off(timeSheetRef)
				off(geoTabRef)
				unsubscribeOffset()
				customerUnsubscribe()
				viewUnsubscribe()
				timeSheetUnsubscribe()
				geoTabUnsubscribe()
			}
		}

	}, [viewData])

	useEffect(() => {
		if (startLoadAllRecords) {
			setStartLoadAllRecords(false)
			try {
				getAllRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			} finally {
			}
		}
	})

	useEffect(() => {
		if (isFirstRender) return
		checkLoadColumnStorage.set(firstPathName, true)
	}, [formColumns])

	useEffect(() => {
		; (async () => {
			try {
				if (_.isEmpty(formColumns) && isFirstRender) return
				//if (environment.formColumnsChanged) return;
				setLoading(true)
				if (checkNeedCallQueryAPI(formColumns.map((item) => item.name)) || isCancelLoadingRecords) {
					if (!_.isEmpty(formColumns)) {
						await loadUpdateColumns()
					}
					setRecordLoading(true)
					setIsCancelLoadingRecords(false)
					checkLoadColumnStorage.set(firstPathName, false)
				} else {
					dispatch({
						type: VIEWS_ACTIONS.DATA_GRID,
						payload: {
							columns: formColumns,
							viewSession: true,
						},
					})
					setLoading(false)
				}
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setLoading(false)
			}
		})()
	}, [formColumns])

	useEffect(() => {
		if (formDeleteSelection.length === 0) return
		setInfo(null)
		const selectedRecords = formDeleteSelection
		if (!ACTIVITY_DEVICE_LIST.includes(viewData.type)) {
			dispatch({
				type: VIEWS_ACTIONS.DATA_GRID,
				payload: {
					records: records.filter((rec) =>
						rec.type === 'DISPATCH'
							? !selectedRecords.includes(rec.dispatchKey)
							: !selectedRecords.includes(rec.submissionKey)
					),
				},
			})
		}
		const infoMessage =
			selectedRecords.length > 1
				? t('common:misc.successfullyDeleted') +
				` ${selectedRecords.length} ` +
				t('common:misc.records') +
				`.`
				: t('common:misc.successfullyDeleted1Record')
		setInfo(infoMessage)
		dispatch({
			type: VIEWS_ACTIONS.FORM_DELETE_SELECTION,
			payload: [],
		})
	}, [formDeleteSelection])

	useEffect(() => {
		if (_.isEmpty(viewUpdate)) return
		setInfo(t('formsData.successfullySavedChanges'))
		const payload = {}
		payload.dataGrid = {
			columns: viewUpdate.columns,
			query: viewUpdate.queries[0],
		}

		const currentFiltersWithUserFilter = getViewClientFilters(
			JSON.parse(viewUpdate.clientFilter),
			environment?.userCurrent?.dataGroupKey
		)
		payload.serverClientFilters = {
			...serverClientFilters,
			[viewUpdate.key]: currentFiltersWithUserFilter,
		}

		dispatch({
			type: VIEWS_ACTIONS.HANDLE_VIEW_UPDATE,
			payload,
		})
	}, [viewUpdate])

	useEffect(() => {
		if (_.isEmpty(viewCreate)) return
		setInfo(t('common:misc.successfullyCreatedView') + ' ')
		setLinkViewInfo(viewCreate)
		dispatch({
			type: VIEWS_ACTIONS.HAS_NEW_VIEW,
			payload: true,
		})
	}, [viewCreate])

	useEffect(() => {
		if (_.isEmpty(viewDeleted.view)) return
		setInfo(null)
		const infoMessage = `${title} ` + t('common:misc.hasBeenDeleted')
		setInfo(infoMessage)
		dispatch({
			type: VIEWS_ACTIONS.VIEW_DELETED,
			payload: { view: viewDeleted, state: true },
		})
		const showViewContent = () => { }

		dispatch({
			type: VIEWS_ACTIONS.HAS_DELETED_VIEW,
			payload: true,
		})
	}, [viewDeleted])

	useEffect(() => {
		if (!viewDeleted.state) return
		if (_.isEmpty(viewData)) return

		if (viewData.key === viewDeleted.view.key) {
			dispatch({
				type: VIEWS_ACTIONS.VIEW_UNAVAILABLE,
				payload: true,
			})
		}
	}, [viewDeleted.state])

	const loadRecords = () => {
		if (_.isEmpty(viewData) || !viewData.key) return
		setLoading(true)
		loadRecordsQuery(viewData.key, viewSession, query.filter, apiToken, viewData.type)
			.then((response) => {
				let queries = [...response.data.view.queries]
				queries[0].filter = { ...query.filter }
				dispatch({
					type: VIEWS_ACTIONS.DATA_GRID,
					payload: {
						queryView: { ...response.data.view, queries: queries },
						records: response.data.records,
					},
				})
			})
			.catch((err) => {
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
				if (err.response.data.code === 403) {
					dispatch({
						type: VIEWS_ACTIONS.DATA_GRID,
						payload: {
							records: [],
						},
					})
				}
			})
			.finally(() => {
				setLoading(false)
				dispatch({
					type: VIEWS_ACTIONS.VIEW_REFRESH,
					payload: false,
				})
			})
	}

	const showLoading = () =>
		loading && (
			<>
				<LoadingSpinner />
				{_.isEmpty(viewData) && <SkeletonLoaderDataGrid />}
			</>
		)

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const showInfoMessage = () =>
		info && (
			<DoformsMessage
				linkViewInfo={linkViewInfo}
				message={info}
				severity={'success'}
				onMessageClosed={handleMessageClosed}
			/>
		)

	const handleMessageClosed = () => {
		setError(null)
		setInfo(null)
		setLinkViewInfo(null)
	}

	const handleResizeColumn = (selectedField, newWidth) => {
		const newColumns = columns.map((col) => {
			if (col.name === selectedField) {
				col.width = newWidth
			}
			return col
		})
		dispatch({
			type: VIEWS_ACTIONS.DATA_GRID,
			payload: {
				columns: newColumns,
			},
		})
	}

	const handleLoadAllRecords = () => {
		setStartLoadAllRecords(true)
	}

	const handleCancelLoadingRecords = () => {
		setIsCancelLoadingRecords(true)
		setStartLoadAllRecords(false)
		cancelLoadNextRecordsAction()
	}

	const onHandleCloseMap = () => {
		dispatch({
			type: VIEWS_ACTIONS.ACTION,
			payload: 'audit',
			showMapCloseIcon: false,
		})
	}

	const showViewDeleted = () => (
		<div style={{ display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
			<Typography variant="h5">This view is not available. Please select another one.</Typography>
		</div>
	)

	const showMapAction = () =>
		action === 'map' && (
			<DoformsMap
				action={'map'}
				tab={'views'}
				title={title}
				environment={environment}
				viewData={viewData}
				formSelected={viewSelected}
				module={module}
				onHandleShowLoading={setLoading}
				onHandleOpenFullScreenFormsTab={onHandleOpenFullScreenTab}
				onHandleCloseMap={onHandleCloseMap}
			/>
		)

	const showAuditAction = () =>
		action === 'audit' && (
			<DoformsWizardDataGrid
				action={action}
				isLoading={loading}
				key={viewData?.key}
				isRefresh={viewRefresh}
				title={title}
				titleFooter={titleFooter}
				environment={environment}
				query={query}
				viewData={viewData}
				formSelected={viewSelected}
				columns={columns}
				records={records}
				queryView={queryView}
				clientFilters={clientFilters}
				serverClientFilters={serverClientFilters}
				recordsLoading={false}
				allRecordsLoading={allRecordsLoading}
				tab={'views'}
				rowsPerPage={rowsPerPage}
				setError={setError}
				onHandledResizeColumn={handleResizeColumn}
				onHandledLoadAllRecords={handleLoadAllRecords}
				onHandledCancelLoadingRecords={handleCancelLoadingRecords}
				onHandleOpenFullScreenViewsTab={onHandleOpenFullScreenTab}
				setLoading={setLoading}
				refreshData={() => {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_REFRESH,
						payload: true,
					})
				}}
			/>
		)

	const onHandleOpenFullScreenTab = () => {
		localStorage.setItem('newFullScreenData_env', JSON.stringify({ environment: environment }))
		localStorage.setItem('newFullScreenData_mod', JSON.stringify({ viewsModule: module }))
		//window.open('/views/' + viewData.key + '?action=' + action, '_blank_' + Math.random())
		window.open(getLinkPrefix('/views'), '_blank_' + Math.random())
	}

	return (
		<div className={classes.root}>
			{showLoading()}
			{showErrorMessage()}
			{showInfoMessage()}
			{!_.isEmpty(viewData) && !_.isEmpty(module.viewSelected) && showMapAction()}
			<div className={classes.datagrid}>
				{viewUnavailable ? showViewDeleted() : showAuditAction()}
			</div>
		</div>
	)
}
export default ViewsData
